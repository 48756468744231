:root {
  --ios-orange: rgb(255, 149, 0);
  --ios-green: rgb(52, 199, 89);
  --ios-blue: rgb(0, 122, 255);

  --background: white;
  --text: black;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  background: var(--background);
  color: var(--text);
}

.condensed {
  letter-spacing: -1px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.flex-container {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-container-space-between {
  justify-content: space-between;
  align-items: baseline;
}

.flex-container-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-container-align-start {
  align-items: flex-start;
}

.show-mobile {
  display: none;
}

.overflow-hidden,
.overflow-hidden div {
  overflow: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gray {
  color: gray;
}

a {
  color: var(--ios-orange);
  text-decoration: none;
}

.header {
  font-size: 1.5em;
  font-weight: bold;
}

.Header {
  font-size: 2em;
  font-weight: bold;
  line-height: 50px;
}

.selector {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.index {
  height: 23px;
  line-height: 23px;
  text-align: center;

  color: var(--ios-green);
  font-size: smaller;
}

.text {
  line-height: 23px;
  margin-bottom: 5px;
}

.sub {
  color: var(--ios-blue);
}

.vertical-buffer {
  height: 10px;
}

.verse-grid {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 3px;
}

.lang-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 3px;
  margin-bottom: 5px;
  line-height: 30px;
}

.lang {
  color: var(--ios-green);
  text-align: center;
}

.lang-selected {
  color: var(--text);
}

.to-lang {
  line-height: 25px;
}

@media screen and (max-width: 543px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: inline;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --ios-orange: rgb(255, 159, 10);
    --ios-green: rgb(48, 209, 88);
    --ios-blue: rgb(10, 132, 255);

    --background: black;
    --text: white;
  }
}
